import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, CircularProgress, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import axios from 'axios';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;
const StartTradingModal = ({ open, onClose, onStartTrading, setTaskStatus,setIsAlgoStarted, setSnackbarMessage}) => {
  const [lotSize, setLotSize] = useState(1);
  const [stopLoss, setStopLoss] = useState(40);
  const [target, setTarget] = useState(40);
  const [selectedInstrument, setSelectedInstrument] = useState('NIFTY');
  const [isLoading, setIsLoading] = useState(false);  // For loading state
  const [errorMessage, setErrorMessage] = useState('');  // For error handling
  const [successMessage, setSuccessMessage] = useState('');  // For success message

const handleSubmit = async () => {
  if (lotSize && stopLoss && target && selectedInstrument) {
    onStartTrading(); // Call the trading initiation function
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    setSnackbarMessage('Starting your trading strategy...');

    // Get the token from local storage
    const token = localStorage.getItem('token'); 

    // Prepare request data
    const requestData = {
      instrument: selectedInstrument,
      lot_size: lotSize,
      book_loss: stopLoss,
      book_profit: target,
    };

    try {
      // Make the API call
      const response = await axios.post(`https://${api_ip}:${api_port}/strategy/build`, requestData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

     // Handle success response
     const successMessage = response.data.message || 'Trading started successfully!';
     setSuccessMessage(successMessage);
     setTaskStatus('PENDING');
     setIsAlgoStarted(true);
     setSnackbarMessage(successMessage);

     window.location.reload(); 

    } catch (error) {
      // Handle error response
      const errorMsg = error.response?.data?.message || 'An error occurred while starting the strategy.';
      setErrorMessage(errorMsg);
      setSnackbarMessage(errorMsg);
    } finally {
      setIsLoading(false); // Ensure loading is turned off
    }
  } 
  else {
    const errorMsg = 'Please fill out all fields before submitting.';
    setErrorMessage(errorMsg);
    setSnackbarMessage(errorMsg);
  }
};

  return (
    <Modal open={open} onClose={onClose}>
    <Box
        sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '3rem',
        borderRadius: '12px', 
        width: '600px', 
        height: 'auto', 
        maxHeight: '90vh', 
        overflowY: 'auto', 
        boxShadow: 24,
        }}
    >
        <Typography variant="h5" sx={{ marginBottom: '1.5rem' }}>
        Connect to Broker
        </Typography>

        {/* Instrument Dropdown */}
        <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
          <InputLabel id="instrument-label">Instrument</InputLabel>
          <Select
            labelId="instrument-label"
            value={selectedInstrument}
            onChange={(e) => setSelectedInstrument(e.target.value)}
            label="Instrument"
          >
            <MenuItem value="NIFTY">NIFTY</MenuItem>
            <MenuItem value="NIFTYBANK">BANKNIFTY</MenuItem>
            <MenuItem value="FINNIFTY">FINNIFTY</MenuItem>
            <MenuItem value="SENSEX">SENSEX</MenuItem>
          </Select>
        </FormControl>

        {/* Lot Size Input */}
        <TextField
        label="Lot Size"
        fullWidth
        value={lotSize}
        onChange={(e) => setLotSize(e.target.value)}
        sx={{ marginBottom: '1.5rem' }}
        />

        {/* Stop Loss Input */}
        <TextField
        label="Stop Loss(%)"
        fullWidth
        value={stopLoss}
        onChange={(e) => setStopLoss(e.target.value)}
        sx={{ marginBottom: '1.5rem' }}
        />

        {/* Target Input */}
        <TextField
        label="Target(%)"
        fullWidth
        value={target}
        onChange={(e) => setTarget(e.target.value)}
        sx={{ marginBottom: '1.5rem' }}
        />

        {/* Submit Button */}
        <Button
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        color="primary"
        sx={{ padding: '1.2rem', fontSize: '1.3rem' }} // Larger button
        disabled={isLoading} // Disable button when loading
        >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>

        {/* Success and Error Messages */}
        {errorMessage && (
        <Typography sx={{ color: 'red', marginTop: '1rem' }}>{errorMessage}</Typography>
        )}
        {successMessage && (
        <Typography sx={{ color: 'green', marginTop: '1rem' }}>{successMessage}</Typography>
        )}
    </Box>
</Modal>

  );
};

export default StartTradingModal;
