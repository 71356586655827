import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  CircularProgress,
  Paper,
  Divider,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const api_ip = process.env.REACT_APP_API_IP;
const api_port = process.env.REACT_APP_API_PORT;

const SignInPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Prepare payload for the API
    const payload = {
      username: email, // Assuming email is used as username
      password,
    };

    try {
      // Send POST request to the API
      const response = await axios.post(`https://${api_ip}:${api_port}/authenticate/login/`, payload);

      // Handle successful login
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('membership_expiry', response.data.membership_expiry);
      localStorage.setItem('username', payload.username);
      navigate('/'); // Redirect to home page
      setError(response.data.message || 'Login successful!');
    } catch (err) {
      // Handle invalid credentials or other errors
      if (err.response?.status === 400) {
        setError(err.response.data.error || 'Invalid credentials!');
      } else {
        setError('Error logging in, please try again');
      }
    }

    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f4f6f8' }}>
      <Container maxWidth="xs">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
          <Paper sx={{ padding: 3, boxShadow: 8, borderRadius: 3 }}>
            <Typography variant="h3" align="center" sx={{ color: '#1976d2', marginBottom: 2, fontWeight: 'bold' }}>
              Sign In
            </Typography>

            {/* Error Message */}
            {error && (
              <Typography variant="body2" color="error" align="center" sx={{ marginBottom: 2 }}>
                {error}
              </Typography>
            )}

            <form onSubmit={handleSubmit}>
              {/* Email Input */}
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: 2 }}
              />

              {/* Password Input with toggle visibility */}
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                required
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Submit Button */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={loading}
                sx={{
                  marginBottom: 2,
                  padding: 1.5,
                  fontSize: '1rem',
                  textTransform: 'none',
                  borderRadius: '50px',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
              </Button>

              <Grid container justifyContent="flex-end" sx={{ marginBottom: 2 }}>
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => alert('Forgot password')}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              <Divider sx={{ marginBottom: 2 }} />

              {/* Sign Up Link */}
              <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Don't have an account?{' '}
                  <Link 
                    component={RouterLink} 
                    to="/sign-up" 
                    variant="body2" 
                    sx={{ color: '#1976d2', textDecoration: 'none' }}
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            </form>
          </Paper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default SignInPage;
